import React from "react"

import { Section, PageContainer } from "../recipes";

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContactLink from "../components/ContactLink"

const Page = () => (
  <Layout>
    <SEO title="About" />
    <PageContainer>
      <PageContainer.Title text="about.title" />
      <Section>
        <Section.Header>Who is this crazy purple-haired lady?</Section.Header>
        <Section.Body>My name is Steph. I'm an industrial chemist by day, and baking is basically the intersection of the lab and food. I love making people happy by filling them full of cake.</Section.Body>
      </Section>
      <Section>
        <Section.Header>How soon can I have cake?</Section.Header>
        <Section.Body>The more notice you can give me the higher the probability that you will be eating cake. But please always ask, I will try my best, everyone deserves cake.</Section.Body>
      </Section>
      <Section>
        <Section.Header>Do you make everything yourself?</Section.Header>
        <Section.Body>Yes! I've developed all my own recipes over the years, for both the cakes and fillings. The only things I buy are some decorations, freeze-dried fruit and the occasional filling like Nutella, but I can talk you through that when ordering.</Section.Body>
      </Section>
      <Section>
        <Section.Header>What decorations do you use?</Section.Header>
        <Section.Body>
          My cakes and cupcakes are decorated with edibles using crumbs, fruit, flowers, sprinkles, jams, curds, chocolate, anything I can get my hands on. I stay clear from
          fondant by choice but if you want it please try me, I do use it for personalised names and digits when the occasion calls.
        </Section.Body>
        <Section.Body>
          Each cake is designed individually, but can be themed or coloured to your ideas, wants and needs. So we can talk about design together or just let me do it for you.
        </Section.Body>
      </Section>
      <Section>
        <Section.Header>Can you cater for my allergies or dietary requirements?</Section.Header>
        <Section.Body>My kitchen is not nut free.</Section.Body>
        <Section.Body>
          I can make vegan options and gluten free options (and with a celiac mother in-law I have had practice). All equipment will be clean prior to use for alternative options
          but please be aware all equipment used has previously been in contact with dairy, gluten and nuts.
        </Section.Body>
        <Section.Body>Vegan and gluten free options will incur additional costs to cover materials.</Section.Body>
      </Section>
      <Section>
        <Section.Header>Do you just make cake?</Section.Header>
        <Section.Body>My focus is on cakes and cupcakes. But, if it's delicious, I've probably made it before and can make it again. In particular, shortbread, gingerbread and peanut butter cookies are my go-tos. Just ask!</Section.Body>
      </Section>
      <Section>
        <Section.Header>Can you deliver?</Section.Header>
        <Section.Body>Yes, if I'm available. Otherwise, standard pickup is from Heathridge, 6027.</Section.Body>
        <Section.Body>The delivery cost will be depend on the destination, please ask for a price.</Section.Body>
      </Section>
      <Section>
        <Section.Header>How do I store my cake?</Section.Header>
        <Section.Body>Cakes do not like to be warm or in the sun. Keep cakes cool and sensible until it’s party time.</Section.Body>
        <Section.Body>If you refrigerate your cake please allow at least an hour at room temperature for best cutting and eating.</Section.Body>
      </Section>
      <Section>
        <Section.Header>How do I pay you?</Section.Header>
        <Section.Body>I take all forms of money, payments can be made by cash or card on the receival of goods. Otherwise, direct deposits prior to the cake day are fine too.</Section.Body>
        <Section.Body>Please know that when placing large cupcake or celebration cake orders a deposit may be requested. This can be refunded if you cancel your order with 7 days notice before the day.</Section.Body>
      </Section>
      <Section>
        <Section.Header>Do you hire out cake stands?</Section.Header>
        <Section.Body>I do have a large number of cake stands and cupcake displays that are available for hire. If this interests you please ask. A deposit may be required as security.</Section.Body>
      </Section>

      <ContactLink>I have more questions</ContactLink>
    </PageContainer>
  </Layout>
);

export default Page;
